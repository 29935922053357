/* Developer ==> Soheil Kashani (mrsio.soheil@gmail.com)
/* Additional styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';

html {
    --background: #F8F8F8;
    --primary: #58BD7D;
    --dark: #09283A;
    --text: #031B29;
    --primaryLight: #E5F1E9;
    --silver: #F1F1F1;
    --whiteStatic: #fff;
    --heroSvg: #fff;
    --navbar: #fff;
    --headingBack: #58BD7D33;
    --border: #09283A ;
    --scrollbar: #ffffffb7;
    --blogCard : #F3F3F3;
    --heroBackColor: #ffffff;
}
  
 html[theme=dark] {
    --background: #031B29;
    --primary: #58BD7D;
    --dark: #09283A;;
    --text: #fff;
    --primaryLight: #58BD7D1F;
    --silver: linear-gradient(270deg, rgba(9,40,58,0.8379945728291316) 0%, rgba(90, 144, 110, 0.152) 100%);;
    --whiteStatic: #fff;
    --heroSvg:#09283A;
    --navbar: #09283A;
    --headingBack: #58BD7D33 ;
    --border: #205671;
    --scrollbar: rgba(51, 51, 51, 0.514)000;
    --blogCard : #0A293A;
    --heroBackColor: #09283A
}

h1,
h2,
h3,
h4 {
    font-family: Morabba !important;
    color: var(--text);
}

body {
    background: var(--background);
    color: var(--text);
    font-family: 'Yekan Bakh' !important;
}

@font-face {
    font-family: 'Yekan Bakh Fat';
    src: url('font/YekanBakh-Fat.eot');
    src: local('YekanBakh-Fat'), url('font/YekanBakh-Fat.eot?#iefix') format('embedded-opentype'), url('font/YekanBakh-Fat.woff2') format('woff2'), url('font/YekanBakh-Fat.woff') format('woff'), url('font/YekanBakh-Fat.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Yekan Bakh';
    src: url('font/YekanBakh-Medium.eot');
    src: local('YekanBakh-Medium'), url('font/YekanBakh-Medium.eot?#iefix') format('embedded-opentype'), url('font/YekanBakh-Medium.woff2') format('woff2'), url('font/YekanBakh-Medium.woff') format('woff'), url('font/YekanBakh-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Yekan Bakh';
    src: url('font/YekanBakh-Bold.eot');
    src: local('YekanBakh-Bold'), url('font/YekanBakh-Bold.eot?#iefix') format('embedded-opentype'), url('font/YekanBakh-Bold.woff2') format('woff2'), url('font/YekanBakh-Bold.woff') format('woff'), url('font/YekanBakh-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Yekan Bakh';
    src: url('font/YekanBakh-Light.eot');
    src: local('YekanBakh-Light'), url('font/YekanBakh-Light.eot?#iefix') format('embedded-opentype'), url('font/YekanBakh-Light.woff2') format('woff2'), url('font/YekanBakh-Light.woff') format('woff'), url('font/YekanBakh-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Yekan Bakh';
    src: url('font/YekanBakh-Heavy.eot');
    src: local('YekanBakh-Heavy'), url('font/YekanBakh-Heavy.eot?#iefix') format('embedded-opentype'), url('font/YekanBakh-Heavy.woff2') format('woff2'), url('font/YekanBakh-Heavy.woff') format('woff'), url('font/YekanBakh-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
/* 
@font-face {
    font-family: Morabba;
    font-style: normal;
    font-weight: 800;
    src: url('Morabba/woff/Morabba-extrabold.woff') format('woff'), url('Morabba/woff2/Morabba-extrabold.woff2') format('woff2');
}

@font-face {
    font-family: Morabba;
    font-style: normal;
    font-weight: 850;
    src: url('Morabba/woff/Morabba-black.woff') format('woff'), url('Morabba/woff2/Morabba-black.woff2') format('woff2');
} */

@font-face {
    font-family: Morabba;
    font-style: normal;
    font-weight: 900;
    src: url('Morabba/woff/Morabba-Heavy.woff') format('woff'), url('Morabba/woff2/Morabba-Heavy.woff2') format('woff2');
}

@font-face {
    font-family: Morabba;
    font-style: normal;
    font-weight: 600;
    src: url('Morabba/woff/Morabba-Regular.woff') format('woff'), url('Morabba/woff2/Morabba-Regular.woff2') format('woff2');
}

::-webkit-scrollbar {
    width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
    cursor: pointer;
}
/* Handle */
::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: var(--scrollbar);
    border-radius: 50rem;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {}

.iconOpen path{
    fill: var(--text) !important;
}

.logoText{
    fill: var(--text);
}

nav li a{
    opacity: .6;
    color: var(--text) !important;
}

.heroSvg{
    fill: var(--heroSvg) !important;
}

.HomeHeroImg {
    width: 72%;
    position: absolute;
    left: -8rem;
    top: -12rem;
    max-width: auto;
    z-index: 0;
}



.HeroCap {
    font-size: 16px;
    color: var(--text);
    opacity: .5 !important;
    font-weight: 300;
    letter-spacing: -.5px;
}

.BuTTon {
    background: var(--silver);
    color: white;
    height: 50px;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    transition: .3s ease;
}

.BuTTon:hover {
    background: var(--dark) !important;
}

.btnGreen {
    background: var(--primary) !important;
}

.btnDark {
    background: var(--dark) !important;
}

.PrimaryText {
    color: var(--primary) !important;
}

.HeadButtons {
    background: var(--silver);
    color: var(--whiteStatic);
    height: 29px;
    border-radius: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 0 25px;
    transition: .3s ease;
}

.BorderBtn {
    border: 1.5px solid var(--text) !important;
    color: var(--text);
    background: transparent !important;
}

.font-medium {
    font-size: 14px !important;
}

.HeaderNav {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    background: var(--navbar);
    padding: 0 1.5rem !important;
    border-radius: 16px;
    height: 90px !important;
}

.max-w-6xl {
    max-width: 76rem !important;
}

header {
    transition: .5s ease !important;
}

header.fixed.shadow-lg {
    margin-top: 0 !important;
}

header.fixed.shadow-lg .HeaderNav {
    background: transparent !important;
    box-shadow: none !important;
}

.coinCard {
    padding: 0 2rem;
}

.coinSlug{
    background: var(--silver);
    display: inline-flex;
    margin-right: 8px;
    padding: 2px 10px;
    font-weight: 700;
    font-size: 11px;
    border-radius: 50rem;
    height: 20px;
    border: 1px solid var(--border);
}

.coinCardTitle .coinCardUpDown {
    background:var(--primary);
    height: 23px;
    padding: 0 10px;
    color: var(--whiteStatic);
    direction: rtl;
    font-weight: 800;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50rem;
    margin-right: 8px;
}

.coinCardTitle {
    color: var(--text);
    font-size: 15px;
}

.coinCardPrice {
    font-weight: 300;
    font-size: 16px;
    opacity: .8;
}

.heroUnit{
    font-size: 10px;
    font-weight: 100;
    margin-right: 4px;
    letter-spacing: 0;
}

.seprator {
    width: 100%;
    /* position: absolute; */
    left: 0;
    right: 0;
    margin: 5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coinCard.down .coinCardTitle span {
    background: #FF6838;
}

.TabsImage {
    position: absolute;
    z-index: 1;
    left: 0;
    top: -8rem;
    width: 46%;
}

.subTitle {
    position: relative;
    font-size: 25px;
    opacity: .6;
    letter-spacing: -1px;
}

.singleTitle {
    letter-spacing: -.5px;
    font-size: 32px;
    font-weight: 900 !important;
}

.subTitle::after {
    content: '';
    position: absolute;
    right: -10px;
    background: #dcdcdc4c;
    height: 20px;
    z-index: -1;
    width: 150px;
    bottom: 12px;
}

.HomeTabs {
    min-height: 400px;
}

.react-tabs__tab-list {
    direction: rtl;
}

.react-tabs__tab--selected {
    background: var(--primaryLight) !important;
    color: var(--primary) !important;
    font-weight: 800;
}

.react-tabs__tab {
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 51px !important;
    border-radius: 10px !important;
    border: none !important;
    padding: 0 15px !important;
    transition: .3s ease;
}

.sepratorColor{
    stop-color: var(--text);
}

.react-tabs__tab-list {
    border: none !important;
}

.react-tabs__tab-panel {
    padding-top: 2rem;
    text-align: right;
    padding-right: 1rem;
}

.tabContentTitle {
    font-size: 20px;
    letter-spacing: -.5px;
    font-weight: 800;
    margin: 1rem 0;
    border-right: 4px solid var(--primary);
    padding-right: 1rem;
    margin-right: -1rem;
}

.tabContentCap {
    font-size: 16px;
    font-weight: 300;
    opacity: .8;
}

.HeroHeading {
    font-size: 43px;
    color: var(--text);
    font-weight: 900;
    letter-spacing: -1px;
}

.HeroHeading::after {
    content: '';
    position: absolute;
    right: 0;
    background: var(--headingBack);
    height: 35px;
    z-index: -1;
    width: 60%;
    top: 10px;
    bottom: 12px;
}

.coinTable {
    text-align: right;
    direction: rtl;
    width: 100%;
}

.coinTable thead th svg,
.coinTable thead th b {
    opacity: .5;
    margin-left: 5px;
    font-size: 14px;
}

.coinTable thead {
    border-bottom: 1px solid #D2D7DA;
}

.coinTable thead tr th {
    padding-bottom: 1.5rem;
}

.tableSearch{
    background-color: transparent;
}

.coinTable tbody td {
    padding: 25px 10px;
}

.tableCoinName {
    font-weight: 300;
    letter-spacing: -1px;
}
.coinTable tbody td {
    font-weight: 300;
}
td.up {
    color: #58BD7D !important;
}

td.down {
    color: #FF6838 !important;
}

.coinTable tr:first-child td {
    padding-top: 3rem;
}

.coinTable tbody tr:hover {
    background: var(--silver);
}

.coinTable tr {
    transition: .3s ease;
}

.coinTable tbody tr td:first-child {
    padding: 0;
}

.coinTable thead tr th:first-child {
    width: 80px;
}

.borderR {
    border-radius: 12px !important;
    height: 48px;
}

.TableToggle button {
    height: 48px;
    transition: .3s ease;
}

.TableToggle button.active {
    color: var(--primary) !important;
    background: var(--primaryLight) !important;
    font-weight: 800;
    border-radius: 12px;
    padding: 0 20px;
}

.maxWcap {
    max-width: 650px;
}

.HomeBanner {
    border-radius: 20px;
    background: var(--silver);
}

.HomeBanner img {
    padding: 0 2rem;
    padding-top: 2rem;
    margin-bottom: -50px;
}

.BlogCardContent {
    background: var(--blogCard);
    border-radius: 20px;
    direction: rtl;
    text-align: right;
    padding: 1rem;
    margin-top: -50px;
    z-index: 1;
    position: relative;
}

.BlogCardContent p {
    color: var(--text);
    opacity: .5;
    font-size: 15px;
    letter-spacing: -.5px;
}

.BCC_Header {
    color: var(--text);
    font-size: 21px;
    font-weight: 900;
    letter-spacing: -1px;
}

.twoSecHeader {
    margin-bottom: .5rem;
    font-size: 22px;
    color: #031B29;
    font-weight: 900;
    letter-spacing: -1px;
}

.Accordions .accordion {
    border: none !important;
}

.Accordions .accordion__item {
    margin-bottom: 1rem;
    border: none !important;
}

.Accordions .accordion__button {
    text-align: right;
    background: var(--silver) !important;
    border-radius: 10px;
    direction: rtl;
    color: var(--text);
    border: none !important;
}

.Accordions .accordion__button::before {
    left: 2rem;
    right: auto;
    top: 23px;
    position: absolute;
}

.Accordions .accordion__button[aria-disabled="true"] {
    background: #58BD7D !important;
    color: var(--text) !important;
    font-weight: 800;
}

.Accordions p {
    direction: rtl;
    text-align: right;
}

.footerBarCard{
    text-align: center;
    color: var(--whiteStatic);
    border-right: 2px solid var(--whiteStatic);

}

.footerBarCard:first-child{
    border: none !important;
}

.footerBar{
    min-height: 76px;
    border-radius: 12px;
    background: var(--primary);
}


.footerCap{
    opacity: .7;
    font-size: 16px;
    font-weight: 100 !important;
}

.footerTitle{
    letter-spacing: -1px;
    font-weight: 800;
    font-size: 20px;
    color: var(--primary);
}

.footerLinks a{
    margin: 10px 0;
    padding: 0;
    color: var(--text);
    opacity: .5;
}

.socialIcons a{
    width: 52px;
    height: 52px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin: 0 5px;
    border-radius: 12px;
    background: var(--silver);
}

.iconClose path{
    fill: var(--text) !important;
}

.headerPhone span{
    margin-top: 4px;
    font-size: 13px;
    opacity: .7;
}

.phoneIcon path{
    fill: var(--text) !important;
}

.coinHeroBar{
    border-radius: 10px;
    padding: 1rem 1.5rem;
    min-height: 73px;
    margin: 3.5rem 0 3rem 0;
    background: var(--primaryLight);
}

.coinBarPrice{
    color: var(--primary);
    font-size: 20px;
}

.op1{
    opacity: 1 !important;
}


.coinBarPrice small{
    font-size: 12px;
    color: var(--text);
    opacity: .5;
    margin-right: 5px;
}

.calculator{
    border-radius: 10px;
    background: var(--blogCard);
}

.inputStyle{
    background: transparent !important;
    border: 1px solid var(--border) !important;
    box-shadow: none !important;
    outline: none !important;
    transition: .3s ease;
    margin-top: -19px;
    padding-top: 20px;
    padding-right: 22px;
    color: var(--text);
}

.inputStyle:focus{
    border-color: var(--primary) !important;
}

.inputStyle::placeholder{
    opacity: .7;
}

.labelStyle{
    margin-right: 12px;
    color: var(--text);
    font-weight: 300;
    font-size: 16px !important;
    letter-spacing: -.5px;
    z-index: 1;
    position: relative;
    display: inline-flex;
    padding: 0 10px;
    background: var(--blogCard);
}

.inputUnit{
    position: absolute;
    bottom: 12px;
    width: 29px;
    left: 12px;
}

.tradeButton{
    height: 45px;
    background: var(--primary);
    width: 100% !important;
    display: flex;
    align-items: center;
    border-radius: 7px;
    justify-content: center;
}

.chNum{
    color: var(--primary);
}

.singlePageTitle{
    font-size: 28px;
    color: var(--primary);
}

.singleSubTitle::after{
    width: 64px !important;
}

.singleBanner{
    text-align: right;
    direction: rtl;
    line-height: 1.8;
    font-weight: 300;
}

.singleBannerImg{
    position: absolute;
    left: 2rem !important;
    bottom: 0;
}

.singleCap{
    direction: rtl;
    margin: 1.5rem 0;
    text-align: right;
}

.singleCap p{
    line-height: 1.9;
    font-weight: 300;
}

.noBack::after,
.noBack::before{
    display: none !important;
}

.coinSteps svg{
    margin: 1.5rem auto;
}

.csHeading{
    color: var(--primary);
    letter-spacing: -.5px;
    font-weight: 800;
    font-size: 19px;
}

.csCaption{
    margin-top: .6rem;
    direction: rtl;
    font-size: 15px;
    font-weight: 300;
}


.spacer{
    max-width: 140px;
    margin: 0 -4rem;
    margin-top: -4.5rem;
}

.featureBox{
    background: var(--silver);
    border-radius: 17px;

}

.featureIMG{
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
}

.featureItem{
    border-left: 1px solid var(--border);

}

.featureItem:first-child{
    border: none !important;
}

.featureItem{
    text-align: center;
}

.featureItem svg{
    margin: 0 auto 1rem auto;
}

.featureHeading{
    font-size: 19px;
    font-weight: 500;
    letter-spacing: -1px;
}

.featureCaption{
    direction: rtl;
    font-weight: 300;
    line-height: 1.9;
    font-size: 15px;
}

.featureBox{
    min-height:350px;
}

.featureCaption{
    min-height: 85.5px;
}

.commentAuthorIcon{
    fill: var(--text);
    opacity: .7;
}

.commentBody{
    font-weight: 300;
    font-size: 15px;
    line-height: 1.9;
}

.commentDate{
    font-size: 15px;
    opacity: .6;
    font-weight: 300;
}

.commentItem{
    background: var(--blogCard);
    border-radius: 15px;
    transition: .3s ease;
    border: 1px solid transparent;
}

.commentItem:hover{
    border-color: var(--primary);
}

.commentsDivs::after{
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), var(--background) 90%);
    width: 100%;
    height: 100%;
}

.commentsDivs{
    max-height: 500px;
    position: relative;
    overflow-y: hidden;
}

.blogIMG{
    width: 100% !important;
    height: 153px;
    border-radius: 14px; 
}
 
.loader {
    width: 48px;
    height: 48px;
    border: 3px dotted #FFF;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted var(--primary);
    border-style: solid solid dotted;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  } 

.loaderWrapper{
    display: flex;
    justify-content: center;
    width: 100%;
}

.uniter{
    opacity: .7;
}


.heroBack{
    fill: var(--heroBackColor);
}

.heroMetaBox{
    z-index: 1;
    position: relative;
}

.heroBox{
    background: #072331;
    border-radius: 20px;
    padding: 1.3rem;
    border: 1px solid #eeeeee3b;
    height: auto;
}

.heroCalculator{
    padding: 1.3rem;
    border: 1px solid #eeeeee3b;
    border-radius: 20px;
    background: rgb(25,123,98);
    background: #0A2E34;
    min-height: 230px;
 }

.heroChartCoin{
    max-height: 196px;
    max-width: 160px;
}

.coinCal input,
.coinCal select{
    background: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance:textfield;
}

.heroSelectBox{
    color: var(--text);
    background: transparent;
}

.selectDiv{
    font-weight: 300;
    font-size: 13px;
}

.coinSection input{
    background: #134544;
}

.coinInput{
    max-height: 24px;
}

.bottomInputs input{
    border: 1px solid var(--border);
    padding: 7px 10px;
    border-radius: 6px;
    height: 35px;
    display: flex;
    font-size: 13px;
    align-items: center;
    justify-content: center;
}

.coinSection label{
    font-weight: 300;
    font-size: 12px;
    text-align: right;
    direction: rtl;
    width: 100%;
    margin-bottom: 4px;
    opacity: .9;
    display: block;
}

.bottomInputs input:focus{
    border: 1px solid var(--border) !important;
    outline: none !important;
    box-shadow: none !important;
}

.rialInput{
    font-size: 13px;
}

.coinGroup{
    align-items: center;
    justify-content: left;
    border: 1px solid var(--border);
    background: #134544;
    height: 35px;
    border-radius: 7px;
}

.coinGroup img{
    max-height: 24px;
}

.inputClass{
    background: transparent !important;
    outline:none !important;
    font-size: 13.5px;
    box-shadow: none !important;
    font-weight: 300;
    border: none !important;
}
 

.coinIcon{
    right: 0;
    position: absolute;
}

.tradeBTN{
    font-size: 13px;
    border-radius: 7px;
    margin-top: 1.3rem;
    background: var(--primary);
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: .3s ease;
}

.tradeBTN:hover{
    background: #072331;
}

.BoxLoader{
    display: flex;
    min-height:230px;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}


.BoxLoader .loader{
    height: 30px;
    width: 30px;
}

@media(min-width:1100px){
    .heroCalculator{
        max-width: 255px;
    }
    .cryptoTH{
        width: 300px !important;
    }
}

@media (max-width:1100px) {
    .heroMetaBox {
        display: block;
        padding: 0 2rem;
        padding-top: 0;
    }
    .heroChartCoin {
        display: none;
    }
    .mob50{
        width: 50%;
        display: inline-block;
    }
    footer .mainLogo{
        display: block !important;
    }
    .featureBox{
        padding: 3rem 0;
    }
    .featureItem{
        border: none;
    }
    .featureIMG{
        z-index: -1;
        opacity: .05;
    }
    .coinSteps svg{
        margin: .5rem auto;
    }
    .coinSteps{
        margin: 3rem 0;
    }
    .spacer{
        display: none;
    }
    .singleBannerImg{
        opacity: .3;
        z-index: -1;
    }
    .TabFooter .BuTTon{
        margin: 1rem 0 0 0 !important;
    }
    .seprator{
        margin: 2rem 0 !important;
    }
    .TabsImage{
        top: 3rem;
        z-index: -1 !important;
        opacity: .2 !important;
    }
   
    .coinCardPrice{
        text-align: right;
    }
    .coinCard img{
        margin-right: 0 !important;
    }
    .coinCard{
        padding: 1rem !important;
        text-align: right;
        margin: 0 !important;
    }
    .scrolls{
        direction: rtl;
        display: flex !important;
        overflow: scroll;
        white-space: nowrap;
    }
    .HeroCap{
        font-size: 15px;
        font-weight: 300;   
    }
    #mobileLogo{
        display: inline-flex !important;
    }
    .socialIcons{
        justify-content: center;
        margin-bottom: 1rem;
    }
    .footerSocial{
        text-align: center;
    }
    .footerCol{
        padding: 1rem .5rem;
    }
    .footerGrids{
        display: block !important;
    }
    .footerBar .footerBarCard{
        margin: 1.5rem 0;
        border: none !important;
        width: 50%;
        display: inline-block;
    }
    .footerBarCard svg{
        display: block;
        width: 100%;
        margin: 0 0 1rem 0 !important;
    }
    .footerBarCard .flex{
        display: block !important;
    }
    .footerBarCard{
        margin: .8rem 0 !important;
    }
    .footerBar{
        display: block !important;
        padding: 1rem 2rem;
    }
    .HomeHeroImg {
        display: none;
    }
    .HeroHeading {
        font-size: 28px;
        margin-top: 0;
    }
    .coinTable tbody tr td:first-child {
        display: none !important;
    }
    .coinTable tbody tr td:nth-child(4) {
        display: none !important;
    }
    .coinTable tbody tr td:nth-child(5) {
        display: none !important;
    }
    .coinTable tbody tr td:nth-child(6) {
        display: none !important;
    }
    .coinTable thead tr th:first-child {
        display: none !important;
    }
    .coinTable thead tr th:nth-child(4) {
        display: none !important;
    }
    .coinTable thead tr th:nth-child(5) {
        display: none !important;
    }
    .coinTable thead tr th:nth-child(6) {
        display: none !important;
    }
    .coinTable thead tr th:nth-child(7) {
        width: 80px;
    }
    .tableCoinName {
        display: block;
    }
    .singleTitle {
        font-size: 25px;
    }
    .twoSecHeader {
        font-size: 18px;
    }
    .headerPhone {
        display: none;
    }
    .coinCard {
        text-align: center;
    }
    .coinCard img {
        margin: auto;
        margin-bottom: 1rem !important;
    }
    .coinCardUpDown {
        margin: 10px 0 0 0 !important;
        display: flex !important;
        align-items: center;
    }
    .coinCardPrice {
        display: block;
        margin-bottom: 10px;
    }
    .HeadButtons{
        width: 25%;
        margin: 0 5px;
    }
    .MobileBar{
        width: 50%;
    }
    .HeaderBranding{
        max-width: 100%;
        width: 100%;
    }

    .headerSign{
        display: none;
    }

    .headerNavbar{
        position: fixed;
        right: 20px;
        top: 8rem;
        /* display: block !important; */
        background: var(--navbar);
        left: 20px;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .headerNavbar ul{
        text-align: right;
        padding: 1.5rem;
        direction: rtl;
        display: block;
    }

    .mainLogo{
        display: none;
    }

    .opened{
        display: block !important;
    }
}

